/* body {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
} */

.candidate {
  position: relative;
  cursor: pointer;
}

.candidate.selected .profileRow {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  background-color: rgba(0, 0, 255, 0.7); /* Semi-transparent blue */
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.overlayText {
  color: white;
  font-weight: bold;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.submitBtn {
  width: 600px;
  height: 60px;
}
